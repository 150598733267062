import { createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../../config';
import { getAccessToken } from '../../../configuration/tokenHandling/tokenHandlingSlice';
import { RootState } from '../../../configuration/setup/store';
import { Asset, AssetWithEmbeddedTags, Driver, DriverWithEmbeddedTags, Tag } from './types';
import { deletionFailure, deletionSuccess, setUnsavedChanges, updateFailure, updateSuccess } from './tagsSlice';

const enableFetchTagLimitFeatureToggle = localStorage.getItem('enableFetchTagLimitFeatureToggle') === 'true';

export const fetchAllTags = createAsyncThunk(
    'tags/fetchTags',
    async ({ accountId }: { accountId: string | null }, thunkAPI) => {
        const accessToken = getAccessToken(thunkAPI.getState() as RootState);
        const url = new URL(`${config.backend.tagService}/tags`);

        if (null !== accountId) {
            url.searchParams.append('account_id', accountId);
        }

        if (enableFetchTagLimitFeatureToggle) {
            url.searchParams.append('limit', '10');
        }

        let nextLink = url.toString();
        let tags: Tag[] = [];

        while (nextLink !== null && nextLink !== undefined) {
            const response = await fetch(nextLink, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const contentType = response.headers.get('content-type');

            if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
                const json = await response.json();
                nextLink = json._links?.next?.href;
                tags = tags.concat(json.items as Tag[]);
            } else {
                return Promise.reject(new Error('Failed to fetch tags'));
            }
        }
        return tags;
    }
);

export const fetchETag = createAsyncThunk('tags/fetchETag', async ({ tagId }: { tagId: string }, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI.getState() as RootState);
    const url = new URL(`${config.backend.tagService}/tags/${tagId}`);

    const tagsEndpoint = `${url.toString()}`;
    const response = await fetch(tagsEndpoint, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });
    const contentType = response.headers.get('content-type');
    const etag = response.headers.get('etag');

    if (response.status === 200 && contentType && contentType.includes('application/json') && etag !== null) {
        return etag;
    } else {
        return Promise.reject(new Error('Failed to fetch eTag'));
    }
});

export const fetchAssets = createAsyncThunk('tags/fetchAssets', async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI.getState() as RootState);
    const assetsEndpoint = `${config.backend.assetService}/assets?embed=(tags)`;
    const loadedAssets: Asset[] = [];
    let nextLink: string | undefined = assetsEndpoint;
    let success: boolean = true;
    while (nextLink) {
        const response: Response = await fetch(nextLink, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        const contentType = response.headers.get('content-type');

        if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
            const json = await response.json();
            const assetsWithTags: AssetWithEmbeddedTags[] = json.items;
            const nextAssets: Asset[] = assetsWithTags.map((assetWithTag) => {
                return {
                    id: assetWithTag.id,
                    accountId: assetWithTag.account_id,
                    name: assetWithTag.name,
                    identification: assetWithTag.identification,
                    identificationType: assetWithTag.identification_type,
                    type: assetWithTag.type,
                    status: assetWithTag.status,
                    brand: assetWithTag.brand,
                    tagIds: assetWithTag?._embedded?.tags?.items?.map((item: { id: string }) => item.id) ?? [],
                };
            });
            loadedAssets.push(...nextAssets);
            nextLink = json._links?.next?.href;
        } else {
            success = false;
            nextLink = undefined;
        }
    }
    if (success) {
        return loadedAssets;
    } else {
        return Promise.reject(new Error('Failed to fetch assets'));
    }
});

export const fetchDrivers = createAsyncThunk('tags/fetchDrivers', async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI.getState() as RootState);
    const driverServiceEndpoint = `${config.backend.driverService}/drivers?embed=(tags)`;
    const loadedDrivers: Driver[] = [];
    let nextLink: string | undefined = driverServiceEndpoint;
    let success: boolean = true;
    while (nextLink) {
        const response: Response = await fetch(nextLink, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        const contentType = response.headers.get('content-type');
        if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
            const json = await response.json();
            const driversWithEmbeddedTags: DriverWithEmbeddedTags[] = json.items;
            const nextDrivers: Driver[] = driversWithEmbeddedTags.map(
                (driverWithEmbeddedTags: DriverWithEmbeddedTags) => ({
                    id: driverWithEmbeddedTags.id,
                    accountId: driverWithEmbeddedTags.account_id,
                    displayName: driverWithEmbeddedTags.display_name,
                    firstName: driverWithEmbeddedTags.first_name,
                    lastName: driverWithEmbeddedTags.last_name,
                    status: driverWithEmbeddedTags.status,
                    tagIds: driverWithEmbeddedTags._embedded?.tags.map((tag: { id: string }) => tag.id) ?? [],
                })
            );
            loadedDrivers.push(...nextDrivers);
            nextLink = json._links?.next?.href;
        } else {
            success = false;
            nextLink = undefined;
        }
    }
    if (success) {
        return loadedDrivers;
    } else {
        return Promise.reject(new Error('Failed to fetch drivers'));
    }
});

export const deleteTags = createAsyncThunk('tags/deleteTags', async ({ tagIds }: { tagIds: string[] }, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI.getState() as RootState);

    for (const tagId of tagIds) {
        const url = new URL(`${config.backend.tagService}/tags/${tagId}`);
        const tagsEndpoint = `${url.toString()}`;

        const response = await fetch(tagsEndpoint, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'If-None-Match': '*',
            },
        });
        if (response.ok) {
            thunkAPI.dispatch(deletionSuccess(tagId));
        } else {
            thunkAPI.dispatch(deletionFailure(tagId));
        }
    }
});

export const updateTag = createAsyncThunk(
    'tags/updateTag',
    async ({ tag, tagVersion }: { tag: Tag; tagVersion: string }, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const accessToken = getAccessToken(state);
        const url = new URL(`${config.backend.tagService}/tags/${tag.id}`);
        const tagsEndpoint = `${url.toString()}`;
        const response = await fetch(tagsEndpoint, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'If-Match': tagVersion,
            },
            body: JSON.stringify(tag),
        });
        if (response.ok) {
            thunkAPI.dispatch(updateSuccess({ tagId: tag.id, name: tag.name }));
            thunkAPI.dispatch(setUnsavedChanges(false));
        } else {
            let errorCode = null;

            if ([400, 404, 409].includes(response.status)) {
                const body: { title: string; status: number; detail: string } = await response.json();

                if (body.detail) {
                    const errorCodeRegex = new RegExp('^\\[(.+)]:.*$');
                    const matches = errorCodeRegex.exec(body.detail);
                    errorCode = matches && matches.length === 2 ? matches[1] : null;
                }
            } else if ([401, 403].includes(response.status)) {
                errorCode = 'UNAUTHORIZED';
            } else if ([500].includes(response.status)) {
                errorCode = 'INTERNAL_SERVER_ERROR';
            } else {
                errorCode = 'UNKNOWN';
            }

            thunkAPI.dispatch(updateFailure({ errorCode }));
        }
    }
);
